import Axios from 'axios';
import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { store } from '@/app/store';
import { BASE_URL_MANAGER } from '@/config';
import { Prop, Watch } from 'vue-property-decorator';

import $ from 'jquery';
import { IUser } from '@/app/store/modules/user/user.models';
import { AdminActionsTypes } from '@/app/store/modules/admin/admin.actions';

@Component({
  name: 'agent-create-form',
})
export default class AgentCreateForm extends VueWizard {
  @Prop() readonly elementId!: string;
  @Prop({ default: true }) readonly autoDisable!: boolean;
  @Prop() readonly parentElementId!: string | undefined;
  @Prop() readonly role!: string;
  @Prop() EventBus!: any;
  @Prop() company!: any;
  public userLoged: IUser = store.state.user!.user;
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  public errorMessage: string = '';
  public validForm: boolean = true;
  public validUserName: boolean = true;
  public validPassword: boolean = true;
  public validUserNameFormat: boolean = true;
  public validSelect: boolean = true;
  public formValues: any = {
    name: {
      firstName: '',
      lastName: '',
    },
    username: '',
    password: '',
    role: this.role,
    company_id: this.company,
  };
  public project_id = '';

  get usernames() {
    return store.state.admin!.usernames;
  }

  created() {
    this.EventBus.$on('reload-modal', () => {
      this.cleanComponent();
    });
  }

  mounted() {
    this.userLoged = store.state.user!.user;
  }

  disable() {
    if (this.autoDisable) {
      $('#' + this.elementId).modal('hide');
    } else {
      this.elementDisableEnable(
        '#' + this.elementId,
        '#' + this.parentElementId,
      );
    }
  }

  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);
    modal.modal('hide');

    modal = $(enableId);
    modal.modal('show');
  }

  get projects() {
    return store.state.admin!.project;
  }

  validatePassword() {
    if (this.formValues.password.length < 8) {
      this.validUserName = false;
      this.errorMessage = 'La contraseña tiene que ser mínimo de 8 caracteres';
    } else {
      this.validUserName = true;
    }
  }

  validateUsername() {
    if (this.usernames.includes(this.formValues.username)) {
      this.validPassword = false;
      this.errorMessage = 'El nombre de usuario escogido ya existe';
    } else {
      this.validPassword = true;
    }
  }

  isEmail(): boolean {
    let serchfind: boolean;

    let regexp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    serchfind = regexp.test(this.formValues.username);

    return serchfind;
  }

  validateUserNameFormat() {
    let usernameSplitted = this.formValues.username.split(' ');
    if (usernameSplitted.length > 1) {
      this.validUserNameFormat = false;
      this.errorMessage = 'El nombre de usuario no puede contener espacios';
    } else {
      this.validUserNameFormat = true;
    }
  }

  validateSelect() {
    if (this.role === 'AGENT') {
      if (this.project_id != '0') {
        this.validSelect = true;
      } else {
        this.validSelect = false;
        this.errorMessage = 'Necesita escoger un proyecto válido';
      }
    }
  }

  async validateForm() {
    let agent_create_url = '';
    let user_add_url = `${BASE_URL_MANAGER}/user/${this.company._id}`;
    let user_id = '';

    this.validatePassword();
    this.validateUsername();
    this.validateUserNameFormat();
    this.validateSelect();
    this.isEmail();

    if (this.role === 'AGENT') {
      this.validForm =
        this.validPassword &&
        this.validUserName &&
        this.validUserNameFormat &&
        this.validSelect;
    } else if (this.role === 'ADMIN') {
      this.validForm =
        this.validPassword && this.validUserName && this.validUserNameFormat;
    }

    if (this.validForm) {
      try {
        const res = await Axios.post(user_add_url, this.formValues);
        user_id = res.data.user._id;

        if (this.role === 'AGENT') {
          agent_create_url = `${BASE_URL_MANAGER}/agent/${user_id}`;
          await Axios.post(agent_create_url, {
            projects: [this.project_id],
          });
        }

        this.$emit('new-agent');

        this.$notification.success({
          placement: 'bottomRight',
          message: 'Usuario guardado exitosamente!',
          description: 'Los datos del usuario han sido guardados',
        });

        this.cleanComponent();
        this.disable();
      } catch (err) {
        this.$notification.warning({
          placement: 'bottomRight',
          message: 'Error',
          description: 'Ha ocurrido un error al guardar los datos',
        });
      }
    }
    store.dispatch(AdminActionsTypes.getAgents(this.company._id));
  }
  close() {
    this.disable();
  }

  cleanComponent() {
    this.errorMessage = '';
    this.validForm = true;
    this.validUserName = true;
    this.validPassword = true;
    this.formValues = {
      name: {
        firstName: '',
        lastName: '',
      },
      username: '',
      password: '',
      role: this.role,
    };
    this.project_id = '0';
  }

  @Watch('company')
  onChildChanged(val: any, oldVal: any) {
    this.company = val;
    this.formValues.company_id = val;
  }
}
