import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import $ from 'jquery';

@Component({ name: 'user-name-modal' })
export default class UserNameModal extends VueWizard {
  @Prop() readonly elementId!: string;
  @Prop() agentNewName!: string;
  @Prop() agentNewLastName!: string;
  @Prop() scopedSlots!: string;
  @Prop() userId!: string;
  @Prop({ default: true }) readonly autoDisable!: boolean;
  @Prop() readonly parentElementId!: string;

  public form = {
    name: '',
    lastName: '',
    password: '',
  };

  public rules = {
    name: [
      {
        required: true,
        message: 'Por favor ingrese el nombre',
        trigger: 'blur',
      },
    ],
    lastName: [
      {
        required: true,
        message: 'Por favor ingrese el apellido',
        trigger: 'blur',
      },
    ],
    password: [
      {
        required: true,
        message: 'Por favor ingrese la contraseña',
        trigger: 'blur',
      },
    ],
  };

  async mounted() {
    this.form.password = '';
    this.form.name = this.agentNewName;
    this.form.lastName = this.agentNewLastName;
  }

  disable() {
    if (this.autoDisable) {
      $('#' + this.elementId).modal('hide');
    } else {
      this.elementDisableEnable(
        '#' + this.elementId,
        '#' + this.parentElementId,
      );
    }
  }

  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);
    modal.modal('hide');
    modal = $(enableId);
    modal.modal('show');
  }

  submit() {
    this.disable();

    this.$emit('ok', {
      agentNewName: this.form.name,
      agentNewLastName: this.form.lastName,
      agentNewPassword: this.form.password,
      userId: this.userId,
    });

    this.form.password = '';
  }
}
