/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VueWizard } from '@/vue-wizard';
import Component from 'vue-class-component';
import { BASE_URL_MANAGER } from '@/config';
import { Prop } from 'vue-property-decorator';
import Axios from 'axios';
import UserNameModal from '@/app/components/agents-management/dedicated-components/user-name-modal/UserNameModal.vue';
import AdminsList from '@/app/components/agents-management/dedicated-components/admins-list/AdminsList.vue';
import AgentCreateForm from '@/app/components/agents-management/dedicated-components/agent-create-form/AgentCreateForm.vue';
import { store } from '@/app/store';
import $ from 'jquery';
import Vue from 'vue';
import moment from 'moment';

@Component({
  name: 'admin-details',
  components: { UserNameModal, AdminsList, AgentCreateForm },
})
export default class AdminDetails extends VueWizard {
  @Prop() readonly elementId!: string;
  @Prop({ default: true }) readonly showActiveAdmin!: boolean;

  public user: any = null;
  public admins: { _id: string; role: string }[] = [];
  public userId = '';
  public EventBus: Vue = new Vue();

  created(): void {
    this.userId = store.state.user!.user._id;
  }

  openAdminCreateModal(actualId: string, newId: string): void {
    this.EventBus.$emit('reload-modal');
    this.elementDisableEnable(actualId, newId);
  }

  isEmpty(obj: any): boolean {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  get getCurrentUser() {
    return store.state.user!.user;
  }

  disable() {
    $(`#${this.elementId}`).modal('hide');
  }

  elementDisableEnable(elementId: string, enableId: any): void {
    let modal: any = $(elementId);
    modal.modal('hide');
    modal = $(enableId);
    modal.modal('show');
  }

  changeUserInfo(values: any): void {
    let agentNewName = values.agentNewName;
    let agentNewLastName = values.agentNewLastName;
    let userId = values.userId;

    let user = this.user;

    if (user && user.name) {
      user.name.firstName = agentNewName;
      user.name.lastName = agentNewLastName;
      store.state.user!.user.name!.firstName = agentNewName;
      store.state.user!.user.name!.lastName = agentNewLastName;
    }

    if (user && user.name) {
      Axios.put(`${BASE_URL_MANAGER}/user/${userId}`, {
        name: { firstName: user.name.firstName, lastName: user.name.lastName },
      });
    }
  }

  formatDate(date: Date): string {
    return moment(date).format('DD/MM/YYYY');
  }

  get retrieveAdmins() {
    return store.state.admin!.agents;
  }
}
