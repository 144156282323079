import {VueWizard} from "@/vue-wizard";
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import AgentListElement from '@/app/components/agents-management/dedicated-components/agent-list-element/AgentListElement.vue'


@Component({
    name: 'admins-list',
    components :{ AgentListElement}
})
export default class AdminsList extends VueWizard{
    @Prop() readonly admins!: any;
   
}